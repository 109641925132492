import {Injectable} from '@angular/core';
import {ThemeConstantService} from './theme-constant.service';
import {ApiService} from '../../services/api.service';
import {MessageService} from './message.service';
import {Notification, User} from '../../../../../database-models';
import {AuthenticationService} from './authentication.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public notificationList: Notification[] = [];
  public unreadNotifications = 0;
  public notificationInterval;

  constructor(
    private themeService: ThemeConstantService,
    private api: ApiService,
    private message: MessageService,
    private authenticationService: AuthenticationService
  ) {

    this.authenticationService.logoutSubject.subscribe(value => {
      console.log('Clear notificationInterval');
      clearInterval(this.notificationInterval);
    });


    this.getNotifications();
    this.notificationInterval = setInterval(() => {
      this.getNotifications();
    }, 60000);
  }

  public getNotifications(): void {
    this.api.getNotification().subscribe((notifications: Notification[]) => {
      this.notificationList = notifications;
      this.calcUnread();
    }, onerror => {
      console.log(onerror);
    });

  }

  private calcUnread(): void {
    let unread = 0;
    for (const notification of this.notificationList) {
      if (!notification.read) {
        unread++;
      }
    }
    this.unreadNotifications = unread;
  }

  public setNotificationToRead(notification: Notification): void {
    if (!notification.read) {
      notification.read = true;
      this.api.updateReadNotification(notification).subscribe(() => {
        this.calcUnread();
      }, error => {
        this.message.error('error.notSaved');
      });
    }
  }

  public setAllNotificationToRead(): void {
      this.api.updateReadNotification({id: 'all'}).subscribe(() => {
        this.getNotifications();
      }, error => {
        this.message.error('error.notSaved');
      });

  }

  public async deleteNotifications(): Promise<void> {

    this.api.deleteNotifications().subscribe(res =>{
      this.getNotifications();
    }, error => {
      this.message.error('Es ist ein Fehler aufgetreten');
    })


  }
}
